<template>
  <div class="option">

    <Head />
    <div class="main">
      <!-- 顶部图片 -->
      <div class="raku-top-pic">
        <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d971a8a51ed.png" alt="" />
        <div class="top-text">
          <img src="../../assets/jingtaiye-pic/fujiafuwu/Additional services.png" alt="" />
          <div class="text">
            <p
              style="font-size: 21px;
                                                                                                                                                                                                                                                                                                                                                                                                                            font-family: Roboto-Regular, Roboto;
                                                                                                                                                                                                                                                                                                                                                                                                                            font-weight: 400;
                                                                                                                                                                                                                                                                                                                                                                                                                            color: #FFFFFF;
                                                                                                                                                                                                                                                                                                                                                                                                                            margin-bottom: 20px;">
              Comprehensive add-on service solutions for the entire supply chain.
            </p>
            <p>
              At Rakumart, you can also enjoy additional services such as FBA labeling, product
            </p>
            <p>
              photography, ODM customization, translation, etc.
            </p>
            <p style="margin-top: 10px;">
              Do you have your own brand? Get in touch with us!
            </p>

          </div>
        </div>
      </div>
      <!-- 自有品牌生产服务 -->
      <div class="Brandproduction">
        <div class="Content">
          <!-- 标题 -->
          <h1 class="title">
            Own brand production services
          </h1>
          <!-- 内容区域 -->
          <!-- 第一格 -->
          <div class="service-two">
            <!-- 右侧 -->

            <!-- 左侧 -->
            <div class="left">
              <h6>OEM Production Services</h6>
              <div class="text">
                <p> The client draws up a first draft, then we commission the <br />
                  manufacturer to produce a completely
                  original
                  product.</p>

                <p style="margin-top: 10px;">Since the process starts with the purchase of materials from the <br />
                  factory, the output will
                  inevitably
                  increase.</p>

                <p style="margin-top: 10px;"> We will contact the manufacturer to find out the production <br />
                  quantity,
                  unit price, lead time and
                  samples
                  from the production </p>
                <p>
                  factory.
                </p>
              </div>
              <!-- 按钮 -->
              <button @click="chat">Request more information from agent</button>
            </div>
            <div class="right">
              <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9722b4826e.svg" alt="" />
            </div>
          </div>

          <!-- 第二格 -->

          <div class="service">
            <!-- 左侧 -->
            <div class="left">
              <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9723f611dc.svg" alt="" />
            </div>
            <!-- 右侧 -->
            <div class="right">
              <h6>Production service ODM</h6>
              <div class="text">
                <p>Customize existing products, adding functions or features that customers want, so that </p>
                <p> customers want, so that they can distribute
                  them under their own</p>
                <p>brand. </p>
                <p style="margin-top: 10px;">This process, like the previous one, starts with the procurement of</p>
                <p>materials
                  from the factory, so the output is increased.</p>
                <p style="margin-top: 10px;">We will contact the manufacturer to find out the production</p>
                <p>quantity, unit price, lead time and samples from the production</p>
                <p>factory.</p>

              </div>
              <!-- 按钮 -->
              <button @click="chat">Request more information from agent</button>
            </div>
          </div>
          <!-- 第三格 -->
          <div class="service-two">
            <!-- 右侧 -->

            <!-- 左侧 -->
            <div class="left">
              <h6>Packaging, Labels and Booklets</h6>
              <div class="text" style="height: 184px;">
                <p>Produce bespoke packaging, labels, packaging, informationa.</p>

                <p>material and unique elements for your own brand.</p>

                <p style="margin-top: 10px;"> Production depends on size, color and type of material required. </p>
                <p>Please provide all elements and information of your brand in order</p>
                <p>to provide you a quote.</p>
                <p style="margin-top: 10px;">
                  Discuss the cost of assembling and adjusting custom elements in
                </p>
                <p>your product with our agents.</p>
              </div>
              <!-- 按钮 -->
              <button @click="chat">Request more information from agent</button>
            </div>
            <div class="right">
              <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d972605b05a.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 为您的产品提供额外的服务 -->
      <div class="Extraservice">
        <h1>Provide Additional Services for Your Products</h1>
        <div class="list">
          <ul>
            <li>
              <div class="left">
                <div>
                  <p>Photograph</p>
                  <p>Providing professional photography regardless the project type or complexity.</p>
                </div>
                <div class="footer_price">
                  <span>1.04$ (7￥) per image</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d971fec8043.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Barcodes Sticking</p>
                  <p>
                    Including replacing plastic bags, PP
                  </p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥) per piece</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d972756f0aa.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Set Packing</p>
                  <p>Packing fee for more than 4 products to be quoted separately。</p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥) per piece</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9728a517e4.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Label Removal</p>
                  <p>We remove the fabric tags that some textiles may have brought from the factory.。</p>
                </div>
                <div class="footer_price">
                  <span>0.23$ (1.5￥)per label</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9729fb092a.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Label Sewing</p>
                  <p>Sewing personalized labels with your branded textiles such as t-shirts, shirts etc.。</p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥) per label</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d972b07ac61.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Tag Hanging</p>
                  <p>Hang your brand label on the products per request.。</p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥) per tag</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d972c4d4e1a.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Tag Removal</p>
                  <p>Remove hanging factory tags from products per request.。</p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥) per tag</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d972dd29708.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>OPP Bag Replacement</p>
                  <p>Including packing OPP bag or replacing OPP bag</p>
                </div>
                <div class="footer_price">
                  <span>0.08$ (0.5￥) per bag</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d972fc62a78.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Bubble Film Packaging</p>
                  <p>Please inquire separately for large items</p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥)per film</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d97310a0677.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Single-sided Printing</p>
                  <p>
                    The default is A4 paper; please contact us for other types of paper or booklet printing
                  </p>
                </div>
                <div class="footer_price">
                  <span>0.15$ (1￥)per copy</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d97324c33e2.png" alt="" />
                </div>
              </div>
            </li>
            <li>
              <div class="left">
                <div>
                  <p>Double-sided Printing</p>
                  <p>
                    The default is A4 paper; please contact us for other types of paper or booklet printing
                  </p>
                </div>
                <div class="footer_price">
                  <span>0.3$ (2￥)per copy</span>
                  <span class="Agentquotation" @click="chat">Quotation to agent</span>
                </div>
              </div>
              <div class="right">
                <div class="pic">
                  <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d973370c099.png" alt="" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 底部图片区域 -->
      <div class="footerpic-div">
        <div class="pic-div">
          <!-- 标题区域 -->
          <div class="img">
            <img src="../../assets/jingtaiye-pic/fujiafuwu/contact.svg" alt="">
          </div>
          <div class="title">
            <h1>Comprehensive add-on service solutions for the entire supply chain</h1>
            <p>
              Tell us what services and products you want, so we can tell you about all the possibilities.
            </p>
            <button @click="chat">Chat with an agent</button>
          </div>
        </div>
      </div>
      <Dialog ref="dialog" :config="config" />
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from '../../components/head/HomePageTop.vue'
import Foot from '../../components/foot/Foot'
import Dialog from '../../components/public/Dialog.vue'
export default {
  data() {
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
    }
  },
  components: {
    Head,
    Foot,
    Dialog
  },
  methods: {
    chat() {
      window.open('https://join.skype.com/invite/pQ1tOpS0Qm88')
      // let element = document.getElementById("zsiq_float");
      // // 创建事件
      // var event = document.createEvent("MouseEvents");
      // // 定义事件 参数： type, bubbles, cancelable
      // event.initEvent("click", true, true);
      // // 触发对象可以是任何元素或其他事件目标
      // element.dispatchEvent(event);
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')

      //     },
      //     () => { }
      //   )
      // }
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  margin: 0 auto;
  margin-top: -25px;

  // overflow: hidden;
  // 顶部图片区域
  // margin-bottom: 200px;
  .raku-top-pic {
    max-width: 100%;
    height: 456px;
    position: relative;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .top-text {
      width: 650px;
      height: 274px;
      position: absolute;
      left: 50%;
      transform: translateX(-106%);
      top: 145px;

      img {
        width: 191px;
        height: 18px;
        margin-bottom: 33px;
      }

      .text {
        width: 780px;
        height: 224px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }

  //自有品牌服务生产
  .Brandproduction {
    margin-top: 80px;
    width: 100%;

    .Content {
      width: 1000px;
      margin: 0 auto;
      min-height: 1000px;
      // background-color: green;

      .title {
        margin: auto;
        width: 435px;
        height: 32px;
        font-size: 24px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        text-align: center;
        line-height: 32px;
        margin-bottom: 40px;
      }

      // 第二个样式
      .service-two {
        display: flex;
        margin-bottom: 80px;

        .right {
          img {
            width: 480px;
            height: 560px;
            background: #F6F6F6;
            border-radius: 10px;

          }
        }

        .left {
          padding-top: 103px;
          flex: 1;

          h6 {
            width: 100%;
            height: 30px;
            font-size: 21px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
            line-height: 30px;
            margin-bottom: 20px;
          }

          .text {
            width: 520px;
            height: 184px;
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
            margin-bottom: 60px;


          }

          button {
            width: 320px;
            height: 60px;
            border-radius: 6px;
            border: 2px solid #FF730B;
            background: #ffffff;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff730b;
            line-height: 60px;
            text-align: center;
          }
        }
      }

      .service {
        display: flex;
        margin-bottom: 80px;

        .left {
          img {
            width: 480px;
            height: 560px;
            background: #F6F6F6;
            border-radius: 10px;
          }
        }

        .right {
          flex: 1;
          padding-left: 40px;
          padding-top: 91px;

          h6 {
            width: 720px;
            height: 30px;
            font-size: 20px;
            font-family: OpenSans-Bold, OpenSans;
            font-weight: bold;
            color: #222222;
            line-height: 30px;
            margin-bottom: 30px;
          }

          .text {
            height: 208px;
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
            margin-bottom: 40x;
            line-height: 24px;
            margin-bottom: 60px;


          }

          button {
            width: 320px;
            height: 60px;
            background: #FF730B;

            font-size: 16px;
            font-size: 16px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            background: #FF730B;
            border-radius: 6px;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }


    }
  }

  // 为您的产品提供额外服务
  .Extraservice {
    border: 1px solid transparent;
    width: 100%;
    margin-top: 80px;

    //  background: blue;
    h1 {
      width: 700px;
      height: 60px;
      height: 32px;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin: auto;
      margin-bottom: 60px;
      text-align: center;
    }

    .list {
      width: 1400px;

      margin: 0 auto;

      ul {
        display: flex;
        width: 105%;
        flex-wrap: wrap;

        li {
          width: 680px;
          height: 220px;
          background: #FFFFFF;
          border-radius: 10px;
          border: 1px solid #E2E2E2;
          margin-right: 40px;
          margin-bottom: 40px;
          background: #ffffff;
          box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
          padding: 30px 40px 30px 40px;
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
              width: 400px;
              height: 24px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
              line-height: 24px;
              margin-bottom: 10px;
              letter-spacing: 1px;
              font-size: 16px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;

              &:first-child {
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                color: #222222;
              }
            }

            .footer_price {
              display: flex;
              justify-content: space-between;

              span {
                font-size: 16px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                color: #666666;
              }

              .Agentquotation {
                min-width: 100px;
                height: 24px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ff6900;
                line-height: 24px;
                letter-spacing: 1px;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .right {
            width: 160px;

            height: 160px;
          }
        }
      }
    }
  }

  // 底部图片区域
  .footerpic-div {
    // margin-top: 40px;
    margin-bottom: 80px;

    .pic-div {
      width: 1400px;
      height: 396px;
      background: #F6F6F6;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 50px;
      padding-top: 80px;


      .img {
        margin: auto;

        width: 54px;
        height: 54px;


        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }

      .title {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        h1 {
          width: 720px;

          font-size: 21px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;

          margin-bottom: 10px;
          text-align: center;
        }

        p {
          width: 800px;
          height: 64px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;

          text-align: center;
        }

        button {
          width: 240px;
          height: 60px;
          border-radius: 6px;
          border: 2px solid #FF730B;
          line-height: 60px;
          text-align: center;
          font-size: 16px;
          font-family: PingFang-SC-Semibold, PingFang-SC;

          font-size: 16px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #FF730B;
          color: #ff730b;

        }
      }
    }
  }
}
</style>
