<template>
  <div>
    <!-- 摄影服务 -->

    <Head />
    <div class="main">
      <!-- 顶部图片 -->
      <div class="raku-top-pic">
        <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d973a1882b3.png" alt="" />
        <div class="top-text">
          <img src="../../assets/jingtaiye-pic/sheyingfuwu/PhotographyServices.png" alt="" />
          <div class="text">
            <p>
              When buying in a physical store, people can actually hold the
              product in their hands and
            </p>
            <p>
              observe it closely, but online shopping is completely different;
              only limited information can
            </p>
            <p>
              be obtained through pictures on the web page. Therefore, the
              quality of the photos on the
            </p>
            <p>
              web page greatly affects the sales of commodities.
            </p>
            <p style="margin-top: 10px;">
              Our company has professional photographers, you may rest assured
              that no matter how
            </p>
            <p>
              complex the requirements are, we can meet them all.
            </p>
          </div>
        </div>
      </div>
      <!-- 中间列表 -->
      <div class="main-list">
        <div class="top-title">
          <div class="txt">Cost - (per product)</div>
          <div class="foot-box">
            <div>Ordinary commodity</div>
            <div><span>7</span><span>yuan / image</span></div>
          </div>
        </div>
        <!-- 中间列表内容区域 -->
        <div class="list">
          <h5 class="list-title">Free Service</h5>
          <div>
            <div class="left">
              <div class="title">
                Mannequin
              </div>
              <div class="text">
                For many products, it is difficult to know the relevant size and
                overall look and feel based on only a two-dimensional photo.
                Clothing is one of them. The size and appearance of suits,
                backpacks and other commodities can be easily displayed through
                the Mannequin.
              </div>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9753559e6e.svg" />
          </div>
          <div>
            <div class="left">
              <div class="title">
                Ironing Service
              </div>
              <div class="text">
                This service can come in handy if you're concerned about
                clothing wrinkling after a shoot. So please feel free to use our
                services.
              </div>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9755a81110.svg" />
          </div>
          <div>
            <div class="left">
              <div class="title">
                Reflections Removal
              </div>
              <div class="text">
                Jewellery and other products will inevitably have reflections
                when shooting, and we will modify them for you free of charge.
              </div>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9758b45272.svg" />
          </div>
          <div>
            <div class="left">
              <div class="title">
                White Background Processing
              </div>
              <div class="text">
                We can add white background to photos for free so that you can
                sell on-line. If there is no remark, we will default to white
                background. If you have other needs, please contact us.
              </div>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d975aa4043f.svg" />
          </div>
          <div>
            <div class="left">
              <div class="title">
                Naming the photos
              </div>
              <div class="text">
                We will name them one by one according to the product
                information, so as not to cause unnecessary trouble to you.
              </div>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d975c1e7b5b.svg" />
          </div>
          <div>
            <div class="left">
              <div class="title">
                Add logo
              </div>
              <div class="text">
                If necessary, we can add a logo for free to prevent your photos
                from being stolen after uploading them on-line.
              </div>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d975e5979b1.svg" />
          </div>
        </div>
      </div>
      <!-- 底部区域 -->
      <div class="footer-box">
        <div class="img">
          <img src="../../assets/jingtaiye-pic/sheyingfuwu/contact.svg" />
        </div>
        <div class="title">
          <p>Paid service</p>
          <p>
            If you need other services, such as photos with model and matching
            packaging photos, please contact us.
          </p>
        </div>
        <div class="bottom" @click="chat">Contact us</div>
      </div>
      <Dialog ref="dialog" :config="config" />
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from '../../components/head/HomePageTop.vue'
import Foot from '../../components/foot/Foot'
import Dialog from '../../components/public/Dialog.vue'
export default {
  data() {
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
    }
  },
  components: {
    Head,
    Foot,
    Dialog
  },
  methods: {
    chat(e) {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);

      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')

      //     },
      //     () => { }
      //   )
      // }
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  margin-top: -25px;
  width: 100%;

  // background-color: pink;
  // padding-bottom: 160px;
  .raku-top-pic {
    width: 100%;
    height: 456px;
    margin: 0 auto;
    position: relative;
    object-fit: cover;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }

    .top-text {
      width: 650px;
      height: 274px;
      position: absolute;
      left: 50%;
      transform: translateX(-106%);
      top: 80px;

      img {
        width: 191px;
        height: 18px;
        margin-bottom: 33px;
      }

      .text {
        width: 700px;
        height: 224px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }

  .main-list {
    width: 1000px;
    height: 1694px;
    padding: 80px 0;
    margin: 0 auto;

    .top-title {
      width: 100%;
      height: 155px;

      .txt {
        margin: 0 auto;
        font-size: 24px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        text-align: center;

        margin-bottom: 40px;
      }

      .foot-box {
        width: 1000px;
        height: 80px;
        background: #fff2e8;
        border-radius: 6px;
        display: flex;
        padding-left: 40px;
        padding-right: 30px;
        align-items: center;
        justify-content: space-between;

        div {
          &:first-child {
            font-size: 16px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
          }

          &:last-child {
            display: flex;
            align-items: center;

            span {
              &:first-child {
                font-size: 24px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                margin-right: 20px;
                color: #ff730b;
              }

              &:last-child {
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
              }
            }
          }
        }
      }
    }

    .list {
      margin: 0 auto;
      width: 1000px;
      padding-top: 80px;

      .list-title {
        height: 32px;
        margin: 0 auto;
        width: 1000px;
        font-size: 24px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        text-align: center;
        margin-bottom: 40px;
      }

      >div {
        width: 1000px;
        height: 180px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #e2e2e2;
        margin-bottom: 30px;
        padding: 30px 30px 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          padding-top: 0px;

          // height: 120px;
          .title {
            margin-top: -40px;
            font-size: 21px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
            margin-bottom: 10px;
          }
        }

        .text {
          width: 740px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
          line-height: 24px;
        }

        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  .footer-box {
    width: 1400px;
    height: 398px;
    background: #f6f6f6;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 81px;
    padding-top: 80px;

    .img {
      margin: 0 auto;
      width: 54px;
      height: 54px;

      margin-bottom: 30px;
    }

    .title {
      p {
        &:first-child {
          font-size: 21px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
          margin: auto;
          margin-bottom: 10px;
          text-align: center;
        }

        &:last-child {
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
          margin: auto;
          margin-bottom: 30px;
          text-align: center;
        }
      }
    }

    .bottom {
      width: 240px;
      height: 60px;
      border-radius: 4px;
      border: 2px solid #ff730b;
      line-height: 60px;
      text-align: center;
      margin: auto;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ff730b;
    }
  }
}
</style>
