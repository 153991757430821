<template>
  <div class="introduction">
    <!-- 公司介绍 -->

    <Head />

    <!-- 顶部图片 -->
    <div class="raku-top-pic">
      <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d973a1882b3.png" alt="" />
      <div class="top-text">
        <img src="../../assets/jingtaiye-pic/gongsijieshao/Company Introduction.png" alt="" />
        <div class="text">
          <p>
            As the operator of RAKUMART US site (www.rakumart.us), Zhejiang
            Lanmao Digital
          </p>
          <p>
            Technology Co., Ltd. is located in Yiwu China, which has the
            world's largest commodity
          </p>
          <p>
            trading market, the most complete commodity supply system,
            thelargest international
          </p>
          <p>
            trade customer group and the most complete international logistics
            channels.
          </p>
          <p style="margin-top: 10px;">
            Before the U.S. site started the digital trade business, RAKUMART
            Japan
          </p>
          <p>
            site (www.rakumart.com) has been deeply involved in the Japanese
            market for many
          </p>
          <p>
            years, we have also successfully implemented South America
            site(www.rakumart.com.br),
          </p>
          <p>
            Korea site (www.rakumart.co.kr), Spain
            site(https://www.rakumart.es), we have very rich
          </p>
          <p>
            experience in the field of digital trade and can provide services
            that exceed expectations.
          </p>
        </div>
      </div>
    </div>
    <!-- 地图 -->
    <div class="map-box">
      <div class="left-text">
        <div class="context">
          <div>
            <p>
              Our affiliates are located in Barcelona, Osaka, Seoul, Sao Paulo,
              Hong Kong and Hangzhou.
            </p>
            <p style=" margin-top: 5px;">
              We have an independent Technology R & D team. The operating area
              of our company exceeds 30,000 square meters and the number of
              employees is 220.
            </p>

            <p style=" margin-top: 5px;">
              We have more than 300,000 high-quality Chinese suppliers, and
              provide one-stop online trade solutions for more than 50,000
              cross-border buyers around the world, such as global marketing,
              information consultation, customer incubation, inquiry and
              quotation, cross-border settlement of funds, procurement,
              collection of goods, quality inspection, transit warehousing,
              international logistics, import and export customs clearance,
              overseas warehouses and after-sales service.
            </p>
            <p style=" margin-top: 5px;">
              Through online trading on the RAKUMART platform, we help Chinese
              suppliers to actively explore the international market, facilitate
              the cross-border direct procurement of fragmented orders from
              small and medium-sized overseas businesses, build and empower the
              innovation and improvement of international supply chains. The
              concept of ‘grow together with partners’ as well as our strict,
              efficient and precise business operations have won us high praise
              from many customers.
            </p>
          </div>
        </div>
      </div>
      <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d973c08980f.svg" alt="" />
    </div>
    <!-- 中间列表 -->
    <div class="midle-list">
      <div>
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d973dd6f291.svg" />
        </div>
        <p>Zhejiang Sameway Supply Chain Co., Ltd.</p>
      </div>
      <div>
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d973f2269e9.svg" />
        </div>
        <p>www.rakumart.us</p>
      </div>
      <div>
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d97407552fc.svg" />
        </div>
        <p>
          No. 35, Jingsan Road, Beiyuan Street, Yiwu City, Zhejiang Province,
          China
        </p>
      </div>
      <div>
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9741b2e362.svg" />
        </div>
        <p>322-000</p>
      </div>
      <div>
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9742dd823f.svg" />
        </div>
        <p> +8615957125759</p>
      </div>
      <div>
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9744d517c9.svg" />
        </div>
        <p>service@rakumart.us</p>
      </div>
      <div style="margin-bottom: 0px;">
        <div class="pic-box">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9745c0915b.svg" />
        </div>
        <p>+8615957125759</p>
      </div>
    </div>
    <!-- 底部列表 -->
    <div class="footer-list">
      <div class="contxt">
        <div class="title">The history of RAKUMART</div>
        <div class="main">
          <div class="left">
            <div class="one">
              <div class="txt">
                <div>In 2016</div>
                <p>Hedao E-Commerce Co., Ltd. established (Yiwu)</p>
              </div>

              <p class="right-border"></p>
              <p class="right-title">01</p>
            </div>
            <div class="two">
              <div class="txt">
                <div>In 2018</div>
                <p>
                  RAKUMART affiliated company - Hangzhou Shenyue Network
                  Technology Co., Ltd. established (Hangzhou)
                </p>
              </div>

              <p class="right-border"></p>
              <p class="right-title">03</p>
            </div>
            <div class="there">
              <div class="txt">
                <div>In 2020</div>
                <p>
                  The RAKUMART small order OEM business platform launched  The
                  RAKUMART B2B2C business platform launched
                </p>
              </div>

              <p class="right-border"></p>
              <p class="right-title">05</p>
            </div>
            <div class="four">
              <div class="txt">
                <div>In 2022</div>
                <p>
                  The RAKUMART European operating entity (Barcelona)
                  established   The Spain site launched online
                </p>
              </div>

              <p class="right-border"></p>
              <p class="right-title">07</p>
            </div>
          </div>
          <div class="right">
            <div class="one">
              <div class="txt">
                <div>In 2017</div>
                <p>
                  Japan RAKUMART Co., Ltd. established (Osaka)
                </p>
              </div>

              <p class="left-border"></p>
              <p class="left-title">02</p>
            </div>
            <div class="two">
              <div class="txt">
                <div>In 2019</div>
                <p>
                  RAKUMART affiliated company - Senwei Global Trading Co., Ltd.
                  established (Hong Kong)
                </p>
              </div>

              <p class="left-border"></p>
              <p class="left-title">04</p>
            </div>
            <div class="there">
              <div class="txt">
                <div>In 2021</div>
                <p>
                  RAKUMART (Korea) Co., Ltd. established   The Korean site
                  launched online RAKUMART Brazil's operating entity (Sao
                  Paulo) established The Brazil site launched online
                </p>
              </div>

              <p class="left-border"></p>
              <p class="left-title">06</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import Head from '../../components/head/HomePageTop.vue'
import Foot from '../../components/foot/Foot'
export default {
  data() {
    return {
      clientWidth: 0,
    }
  },
  components: {
    Head,
    Foot,
  },
  mounted() {
    this.clientWidth = document.body.clientWidth
  },
  methods: {},
}
</script>
<style scoped lang="scss"></style>
<style lang="scss" scoped>
.introduction {
  width: 100%;
  margin: auto;
  margin-top: -25px;

  // background-color: pink;
  .raku-top-pic {
    width: 100%;
    height: 456px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .top-text {
      width: 650px;
      height: 274px;
      position: absolute;
      left: 50%;
      transform: translateX(-106%);
      top: 80px;

      img {
        width: 191px;
        height: 18px;
        margin-bottom: 33px;
      }

      .text {
        width: 680px;
        height: 224px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }

  .map-box {
    width: 1400px;
    margin: auto;
    height: 500px;
    // background-color: yellow;
    margin-top: 120px;
    display: flex;

    .left-text {
      width: 570px;
      height: 408px;
      font-size: 16px;
      margin: 46px 0;

      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
      line-height: 24px;

      .context {
        position: relative;

        div {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      p {
        width: 650px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
      }
    }

    img {
      width: 860px;
      height: 500px;
    }
  }

  .midle-list {
    width: 1400px;
    margin: 0 auto;

    margin-top: 120px;
    height: 520px;

    // background-color: pink;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    div {
      width: 650px;
      height: 100px;
      // background: pink;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      position: relative;

      // justify-content: center;
      .pic-box {
        width: 100px;
        height: 100px;

        border: 1px solid #e2e2e2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        background: #ffff;
        top: 0;
      }

      img {
        width: 80px;
        height: 80px;
      }

      p {
        width: 600px;
        height: 60px;
        background: #f6f6f6;

        border-radius: 6px;
        line-height: 60px;
        padding-left: 60px;
        overflow: hidden;
        /*溢出隐藏*/
        text-overflow: ellipsis;
        /*溢出显示省略号*/

        white-space: nowrap;
        /*强制不换行*/
        margin-left: 50px;
      }
    }
  }

  .footer-list {
    max-width: 1920px;
    margin: auto;
    margin-top: 100px;
    height: 1078px;
    background: #f6f6f6;

    padding: 120px 560px;

    .contxt {
      .title {
        margin: 0 auto;
        width: 310px;
        height: 35px;
        font-size: 24px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
      }

      .main {
        width: 800px;
        height: 804px;
        display: flex;
        padding-top: 32px;

        .left {
          min-width: 50%;
          height: 804px;

          // background-color: pink;
          .one {
            position: relative;
            width: 100%;
            height: 98px;
            border-bottom: 2px solid #ff730b;

            .right-border {
              position: absolute;
              right: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #ff730b;
            }

            .right-title {
              position: absolute;
              right: -15px;
              top: 25px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              // bottom: -10px;
            }

            .txt {
              width: 100%;
              height: 100%;
              padding-bottom: 10px;

              div {
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                margin-bottom: 10px;
                color: #222222;
              }

              p {
                width: 320px;

                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 24px;
              }
            }
          }

          .two {
            margin-top: 100px;
            position: relative;
            width: 100%;
            height: 122px;
            border-bottom: 2px solid #ff730b;

            .right-border {
              position: absolute;
              right: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #ff730b;
            }

            .right-title {
              position: absolute;
              right: -15px;
              top: 50px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              // bottom: -10px;
            }

            .txt {
              width: 100%;
              height: 100%;
              padding-bottom: 10px;

              div {
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                margin-bottom: 10px;
                color: #222222;
              }

              p {
                width: 320px;

                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 24px;
              }
            }
          }

          .there {
            margin-top: 70px;
            position: relative;
            width: 100%;
            height: 154px;
            border-bottom: 2px solid #ff730b;

            .right-border {
              position: absolute;
              right: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #ff730b;
            }

            .right-title {
              position: absolute;
              right: -15px;
              top: 80px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              // bottom: -10px;
            }

            .txt {
              width: 100%;
              height: 100%;
              padding-bottom: 10px;

              div {
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                margin-bottom: 10px;
                color: #222222;
              }

              p {
                width: 320px;

                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 24px;
              }
            }
          }

          .four {
            margin-top: 92px;
            position: relative;
            width: 100%;
            height: 130px;
            border-bottom: 2px solid #ff730b;

            .right-border {
              position: absolute;
              right: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #ff730b;
            }

            .right-title {
              position: absolute;
              right: -15px;
              top: 55px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              // bottom: -10px;
            }

            .txt {
              width: 100%;
              height: 100%;
              padding-bottom: 10px;

              div {
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                margin-bottom: 10px;
                color: #222222;
              }

              p {
                width: 320px;

                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 24px;
              }
            }
          }
        }

        .right {
          width: 50%;
          height: 804px;

          // background-color: yellow;
          .one {
            margin-top: 110px;
            width: 100%;
            position: relative;
            height: 98px;
            border-bottom: 2px solid #698cff;
            // background-color: pink;
            margin-left: 2px;

            .left-border {
              position: absolute;
              left: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #698cff;
            }

            .left-title {
              position: absolute;
              left: -15px;
              top: 25px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              bottom: -10px;
            }

            .txt {
              margin-left: 100px;

              padding-bottom: 10px;

              div {
                width: 100%;
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                color: #222222;
                text-align: right;
                margin-bottom: 10px;
              }

              p {
                width: 300px;
                height: 48px;
                text-align: right;
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                line-height: 24px;
                color: #222222;
              }
            }
          }

          .two {
            margin-top: 100px;
            width: 100%;
            position: relative;
            height: 122px;
            border-bottom: 2px solid #698cff;
            // background-color: pink;
            margin-left: 2px;

            .left-border {
              position: absolute;
              left: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #698cff;
            }

            .left-title {
              position: absolute;
              left: -15px;
              top: 50px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              bottom: -10px;
            }

            .txt {
              margin-left: 100px;

              padding-bottom: 10px;

              div {
                width: 100%;
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                color: #222222;
                text-align: right;
                margin-bottom: 10px;
              }

              p {
                width: 300px;
                height: 48px;
                text-align: right;
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 24px;
              }
            }
          }

          .there {
            margin-top: 30px;
            width: 100%;
            position: relative;
            height: 194px;
            border-bottom: 2px solid #698cff;
            // background-color: pink;
            margin-left: 2px;

            .left-border {
              position: absolute;
              left: -2px;
              bottom: -10px;
              width: 2px;
              height: 40px;
              background: #698cff;
            }

            .left-title {
              position: absolute;
              left: -15px;
              top: 125px;
              font-size: 24px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #222222;
              bottom: -10px;
            }

            .txt {
              margin-left: 100px;

              padding-bottom: 10px;
              padding-top: 20px;

              div {
                width: 100%;
                font-size: 21px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                color: #222222;
                text-align: right;
                margin-bottom: 10px;
              }

              p {
                width: 300px;
                height: 48px;
                text-align: right;
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
