<template>
  <div class="recommend">

    <Head />
    <div class="main">
      <!-- 顶部图片 -->
      <div class="raku-top-pic">
        <div class="top-text">
          <div class="text">
            <p>
              Rakumart makes importing easier
            </p>
            <p>
              Rakumart is an all-in-one import platform, we will provide you with <br>
              tens of thousands of suppliers to enable you to import products at<br>
              a more competitive price. We will also manage the entire <br>
              procurement, logistics and import process for you.
            </p>
          </div>
          <div class="videoBox">
            <div class="edy-texteditor-container embed-container edy-positionable-container-maxwidth"
              contenteditable="false" unselectable="on" style="
            max-width: 100%;
            overflow: hidden;
            position: relative;
            width: auto;
          ">
              <div class="edy-padding-resizer-wrapper" style="padding-bottom: 56.25%">
                <iframe id="ifrId" src="https://www.youtube.com/embed/pvPoIFyYaH0" frameborder="0" style="
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 20px;
              "></iframe>
              </div>
            </div>
          </div>

        </div>

      </div>
      <!-- 中间列表区域 -->
      <div class="list">
        <!--aboutus  -->
        <div class="aboutus">
          <h3>About us</h3>
          <div class="content">
            <div class="textbox">
              <div class="title">
                The most powerful B2B e-commerce solution from China
              </div>
              <p style="margin-bottom: 10px">
                Rakumart is a B2B company, whose main goal is to help American merchants import products from China in
                the
                most cost-effective way, to save costs and simplify processes, and to provide customers with the best
                import
                experience.
              </p>
              <p>
                We are headquartered in Yiwu, China, with an office operation area of more than 4,000 square meters and
                a
                warehouse of 15,000 square meters. We have 300 employees in the group company and an independent
                technical
                research and development team.
              </p>
            </div>


            <div class="pic-box">
              <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96b0771228.png" alt="" />
            </div>
          </div>
        </div>
        <!-- whychooseus -->
        <div class="whychooseus">
          <h3>Why choose us</h3>
          <div class="content">
            <div class="pic-box">
              <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96b3578b49.png" alt="" />
            </div>
            <div class="text-box">
              <h3>
                Simplify your procurement and import from China and optimize all costs
              </h3>
              <div class="text-box-title">
                <p>
                  The English interface makes it easy to complete the procurement process.
                </p>
                <p>
                  Provide quality control, warehousing, international logistics, customs clearance, additional services
                  and
                  other services.
                </p>

                <p>
                  English customer service is convenient for communication and after-sales
                </p>
              </div>

              <p></p>
              <div class="text-box-list">
                <div>
                  <span><img src="../../assets/jingtaiye-pic/womenshishei/selected.png" alt="" /></span>
                  <span>Optimize transportation and import costs</span>
                </div>
                <div>
                  <span><img src="../../assets/jingtaiye-pic/womenshishei/selected.png" alt="" /></span>
                  <span>The whole import process from a single site</span>
                </div>
                <div>
                  <span><img src="../../assets/jingtaiye-pic/womenshishei/selected.png" alt="" /></span>
                  <span>Warehousing and full import management</span>
                </div>
                <div>
                  <span><img src="../../assets/jingtaiye-pic/womenshishei/selected.png" alt="" /></span>
                  <span>Bilingual customer service and after-sales service</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- last-box -->
        <div class="last-box">
          <div class="text-box">
            <h3>
              Find everything you're looking for The world's factory in your hands
            </h3>
            <p style="margin-bottom: 8px">
              Currently, users can find any product from Alibaba, 1688, and other large domestic Chinese e-commerce
              platforms on Rakumart.
            </p>
            <p>
              Our large network of collaborators enables us to offer you a wide variety of products and over 100,000
              quality
              Chinese suppliers.
            </p>


          </div>
          <div class="pic-box">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96ba0b7134.png" alt="" />
          </div>
        </div>
      </div>
      <!-- total solution区域 -->
      <div class="totalsolution">
        <h3>
          Our large network of collaborators enables us to offer you a wide variety of products and over 100,000 quality
          Chinese suppliers.
        </h3>
        <p>
          At Rakumart, you will also enjoy additional services such as FBA labeling, product photography, OEM
          customization and translation.
        </p>
        <div class="list">
          <div class="item">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96c81106c7.png" alt="" />
            <div class="item-text">
              <h3>Photography service</h3>
              <p>
                We provide professional photography services for your products, regardless of their features or
                complexity.
              </p>
            </div>
          </div>
          <div class="item">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96ca4e437c.png" alt="" />
            <div class="item-text">
              <h3>Customization</h3>
              <p>
                We manage the customization and labeling of your products to suit your brand.
              </p>
            </div>
          </div>
          <div class="item">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96cbeb18a8.png" alt="" />
            <div class="item-text">
              <h3>Packaging and brochures</h3>
              <p>
                We can import products with packaging and personalized documents for your company.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--  profitability-->
      <div class="profitability">
        <div class="top-text">
          <h3>
            You can focus more on your business and improve profitability.  
          </h3>
          <p>
            Rakumart is a one-stop service import platform; we can provide you with all services throughout the
            procurement and transportation process.
          </p>
          <div class="item">
            <span class="left-pic">
              <img src="../../assets/jingtaiye-pic/womenshishei/icon1.svg" alt="" />
            </span>
            <div class="right_text">
              <p>Comprehensive and easy management for imports from China</p>
              <p>
                You can focus more on your business and improve your profitability
              </p>
            </div>
          </div>
          <div class="item">
            <span class="left-pic">
              <img src="../../assets/jingtaiye-pic/womenshishei/icon2.svg" alt="" />
            </span>
            <div class="right_text">
              <p>Find all products with the cheapest price</p>
              <p>
                Access more than 100,000 quality Chinese suppliers at the best prices.
              </p>
            </div>
          </div>
          <div class="item">
            <span class="left-pic">
              <img src="../../assets/jingtaiye-pic/womenshishei/icon1.svg" alt="" />
            </span>
            <div class="right_text">
              <p>Warehouse storage and quality control</p>
              <p>
                We utilize an advanced warehouse management system and strict quality control processes to ensure the
                safety and high quality of every product.
              </p>
            </div>
          </div>
          <div class="item">
            <span class="left-pic">
              <img src="../../assets/jingtaiye-pic/womenshishei/icon2.svg" alt="" />
            </span>
            <div class="right_text">
              <p>Additional solutions and services for your products</p>
              <p>
                Provide a wide range of additional services to meet all your needs.
              </p>
            </div>
          </div>

        </div>


      </div>
      <!-- map-div -->
      <div class="map-div">
        <div class="text-box">
          <h3>
            With a presence on three continents, our goal is
            Changing the business world with China
          </h3>
          <p>
            Rakumart currently operates on three continents with subsidiaries located in Barcelona (Spain), Osaka
            (Japan),
            Seoul (Korea), São Paulo (Brazil), Hong Kong and Hangzhou (China).
          </p>
          <p style="margin-top: 8px">
            We aim to cover the world's major language markets, enter into the B2B e-commerce world from China, open up
            new
            international markets for more small and medium-sized domestic enterprises in China, and let more overseas
            buyers
            enjoy the globalization results of RAKUMART's "inclusive e-commerce".
          </p>
        </div>


        <div class="img">
          <img src="../../assets/jingtaiye-pic/womenshishei/map_rakumart.svg" alt="" />
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";

export default {
  name: 'quienes-somos',
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
  methods: {

  },

  mounted() {
    // const iframe = document.getElementById('ifrId');
    // iframe.contentWindow.postMessage('changeStyle', '*');
    // window.addEventListener('message', function (event) {
    //   if (event.data === 'changeStyle') {
    //     document.body.style.backgroundColor = 'yellow';
    //   }
    // });
  }
};
</script>

<style lang='scss' scoped>
.main {
  margin: auto;
  margin-top: -25px;
  width: 100%;
  overflow: hidden;

  .raku-top-pic {
    width: 100%;
    background: url('https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66daceca6a813.png') no-repeat;
    background-size: 100% 100%;
    height: 600px;

    .top-text {
      width: 1400px;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .img {
        width: 191px;
        height: 18px;
        margin-bottom: 35px;
        font-size: 20px;
        color: #e06817;
      }

      .text {
        width: 700px;

        p {
          font-weight: 400;
          font-size: 20px;
          color: #222222;
          line-height: 30px;
        }




        p:first-child {
          font-weight: bold;
          font-size: 48px;
          line-height: 50px;
          margin-bottom: 40px;
        }
      }

      .videoBox {
        margin: 0;
        width: 600px;
        height: 315px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
      }

    }
  }

  .list {
    margin: auto;
    width: 1400px;
    margin-top: 91px;

    .aboutus {
      width: 100%;
      height: 661px;
      margin-bottom: 120px;

      >h3 {
        width: 100%;
        text-align: center;
        margin-top: 29px;
        font-size: 24px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
      }

      .content {
        margin-top: 40px;
        width: 100%;
        height: 560px;
        display: flex;

        .textbox {
          flex: 1;
          margin-right: 70px;
          padding-top: 179px;

          .title {
            font-size: 21px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
            margin-bottom: 20px;
            text-align: left;
          }

          p {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
          }
        }

        .picbox {
          width: 650px;
          height: 560px;
          border-radius: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .whychooseus {
      width: 100%;
      height: 658px;

      >h3 {
        font-size: 24px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        margin-bottom: 40px;
        text-align: center;
      }

      .content {
        display: flex;

        .pic-box {
          width: 650px;
          height: 560px;
          border-radius: 10px;
          margin-right: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .text-box {
          flex: 1;
          padding-top: 56px;

          h3 {
            font-size: 21px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
            line-height: 30px;
            margin-bottom: 20px;
          }

          .text-box-title {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
            line-height: 24px;

            p {
              margin-bottom: 5px;
            }
          }

          >p {
            margin-top: 50px;
            width: 560px;
            height: 1px;
            background: #e2e2e2;
            margin-bottom: 50px;
          }

          .text-box-list {
            div {
              display: flex;

              align-items: center;
              margin-bottom: 20px;

              span {
                &:first-child {
                  display: flex;
                  width: 20px;
                  height: 20px;
                  background: #ffab6c;
                  border-radius: 50%;
                  justify-content: center;
                  margin-right: 10px;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }

    .last-box {
      width: 1400px;
      margin-top: 120px;
      height: 380px;
      display: flex;

      .text-box {
        flex: 1;

        h3 {
          margin-top: 113px;
          font-size: 21px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
          margin-bottom: 20px;
        }

        p {
          width: 680px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
          line-height: 24px;
        }
      }

      .pic-box {
        width: 520px;
        height: 380px;

        img {
          width: 520px;
          height: 380px;
        }
      }
    }
  }

  .totalsolution {
    width: 1400px;
    margin: auto;
    margin-top: 120px;
    height: 360px;
    margin-bottom: 120px;

    width: 1400px;

    h3 {
      font-size: 21px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin-bottom: 20px;
    }

    >p {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
    }

    .list {
      margin-top: 40px;
      width: 1400px;
      display: flex;
      justify-content: space-between;

      .item {
        width: 440px;
        height: 240px;
        background: #e0e7ff;
        border-radius: 10px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .item-text {
          position: absolute;
          left: 46px;
          bottom: 20px;
          padding-right: 8px;

          h3 {
            font-size: 18px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 10px;
          }

          P {
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
    }
  }

  .profitability {
    width: 1400px;
    margin: auto;

    .top-text {
      width: 1400px;


      h3 {
        font-size: 21px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        margin-bottom: 40px;
      }

      .item {
        width: 100%;
        height: 80px;
        display: flex;
        margin-bottom: 40px;



        .left-pic {
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }

        .right_text {
          height: 80px;
          padding: 10px 0;

          p {
            &:first-child {
              font-size: 18px;
              font-family: Roboto-Medium, Roboto;
              font-weight: 500;
              color: #222222;
              margin-bottom: 10px;
            }

            &:last-child {
              font-size: 16px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }


    }

    // >h3 {
    //   margin-top: 40px;
    //   font-size: 21px;
    //   font-family: Roboto-Medium, Roboto;
    //   font-weight: 500;
    //   color: #222222;
    // }
  }

  .map-div {
    margin: auto;
    width: 100%;
    margin-top: 120px;
    padding-top: 30px;
    height: 560px;
    background: #001869;

    position: relative;

    .text-box {
      position: absolute;
      // left: 260px;
      top: 152px;
      left: 50%;
      transform: translateX(-106%);


      h3 {
        width: 650px;
        height: 60px;
        font-size: 21px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        margin-bottom: 20px;
      }

      P {
        width: 680px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }

    .img {
      width: 860px;
      height: 500px;
      margin-top: 30px;


      position: absolute;
      // left: 42%;
      right: 50%;
      transform: translateX(82%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>